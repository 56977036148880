<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">优秀论文</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <div style="display: inline-block;text-align:center;width: 100%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/lw/基于“数据有机体”的国土空间规划智慧化支撑体系构建探索与实践.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">基于“数据有机体”的国土空间规划智慧化支撑体系构建探索与实践——以大连市国土空间规划“一张图”数据治理为例</div> 
        </div>
        <div style="display: inline-block;text-align:center;width: 100%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/lw/国土空间规划体系下详细规划编制和实施管理制度改革的地方实践样本.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">国土空间规划体系下详细规划编制和实施管理制度改革的地方实践样本——大连市详细规划编制管理实斾机制研究的探索与实践</div> 
        </div>
        <table cellspacing="0" style="width:100%;border:none;" class="ke-zeroborder">
          <tbody>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">序号</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">论文名称</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">获奖情况</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">类别</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
              <td width="142" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <b><span style="font-family:华文仿宋;font-size:18px;">年份</span></b><b><span style="font-family:华文仿宋;font-size:14pt;"></span></b> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">1</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:justify;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">《基于“数据有机体”的国土空间规划智慧化支撑体系构建探索与实践——以大连市国土空间规划“一张图”数据治理为例》</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">三等奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">全国国土空间规划年会</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="147" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2023</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
            <tr>
              <td width="85" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="372" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:justify;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">《国土空间规划体系下详细规划编制和实施管理制度改革的地方实践样本——大连市详细规划编制管理实斾机制研究的探索与实践》</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="148" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">优秀奖</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="235" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">全国国土空间规划年会</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
              <td width="142" valign="center" style="border:1px solid #000000;">
                <p class="MsoNormal" align="center" style="text-align:center;vertical-align:middle;">
                  <span style="font-family:华文仿宋;font-size:18px;">2023</span><span style="font-family:华文仿宋;font-size:14pt;"></span> 
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>